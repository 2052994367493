import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as env } from "../../../environments/environment";
import { GeneratePresignedUrlResponseItem } from '../models/api';
import { S3ResponseItem } from '../models/S3ResponseItem';
import { UploadDocResult } from '../models/UploadDocResult';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor(private _client: HttpClient) { }

  public uploadFilesToS3(files: NzUploadFile[], items: GeneratePresignedUrlResponseItem[]): Observable<S3ResponseItem[]> {
    const s3Responses = items.map((item: GeneratePresignedUrlResponseItem) => {
      const file = files.find((f: NzUploadFile) => f.name === item.filename);
      const s3Response = this.uploadFile(item.url, file);
      return s3Response.pipe(
        map((response: Response) => {
          const result: S3ResponseItem = {
            success: response.ok,
            filename: file.name,
            key: item.key,
            uid: file.uid,
            bucketName: item.bucketName
          };
          return result;
        })
      );
    });

    return forkJoin(s3Responses);
  }


  private uploadFile(url: string, file: any): Observable<Response> {
    const response = fetch(url, {
      method: "put",
      body: file
    });
    return from(response)
  }


  public legacyUploadFile(info: UploadDocResult, file: any): Observable<HttpResponse<Object>> {
    const url = info.url;
    const formData = new FormData();

    for (let [key, value] of Object.entries(info.fields)) 
    {
      formData.append(key, value as string);
    }

    formData.append("file", file);

    return this._client.post(url, formData, { observe: "response" });

  }


  






}
