import { Injectable } from '@angular/core';
import { TpoContact } from '../models/TpoContact';
import { environment as env } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TpoContactService {

  private localStorageKey: string = "tpo_contact";

  constructor() { }

  public save(tpoContact: TpoContact): void {
    const str: string = JSON.stringify(tpoContact);
    localStorage.setItem(this.localStorageKey, str);
  }

  public delete(): void {
    localStorage.removeItem(this.localStorageKey);
  }

  public get() {
    const str = localStorage.getItem(this.localStorageKey);
    return JSON.parse(str) as TpoContact;
  }

  public isAuthorized(): boolean {
    const tpoContact = this.get();
    if (tpoContact && tpoContact.allowedAccess && tpoContact.channelCode !== "None")
    {
      return true;
    }
    else 
    {
      return false;
    }

  }



  public getChannelCodeNames(): string[] {
    if (this.get().channelCode === "Both")
    {
      return ["Broker", "Correspondent"]
    }
    else {
      return [this.get().channelCode];
    }
  }
}
