import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment as env } from "../../../environments/environment";
import { ApiResponse, GeneratePresignedUrlResponseItem } from '../models/api';
import { ApiGatewayResponse, CancelSubmissionResponse, GenerateRequestSubmissionIdResponse, ReleaseSubmissionResponse, SubmitResponse } from '../models/apigateway';
import { BsaSubmissionRequest } from '../models/BsaSubmissionRequest';
import { UploadDocResult } from '../models/UploadDocResult';

@Injectable({
  providedIn: 'root'
})
export class ApiGatewayService {

  constructor(private _client: HttpClient) { }

  /**
   * @deprecated
   */
  public getRequestSubmissionId(): Observable<ApiResponse<string>> {
    const url: string = `${env.apiGatewayUri}/setup/gid`;
    const response = this._client.get<GenerateRequestSubmissionIdResponse>(url).pipe(
      map((response: GenerateRequestSubmissionIdResponse) => {
        return {
          success: response.statusCode === 200,
          message: "Success",
          result: response.body.RequestSubmissionId
        }
      })
    )
    return response;
  }

  /**
   * @deprecated
   */
  public submitBsaRequest(request: BsaSubmissionRequest): Observable<ApiResponse<any>> {
    const url = `${env.apiGatewayUri}/submissions/submit`;
    const response = this._client.post<SubmitResponse>(url, request).pipe(
      map((response: ReleaseSubmissionResponse) => {
        return {
          success: response.statusCode === 200,
          message: response.body.status,
          result: response.body.status
        }
      })
    )
    return response;
  }

  /**
   * @deprecated
   */
  public uploadDoc(filename: string, requestSubmissionId: string): Observable<ApiGatewayResponse<UploadDocResult>> {
    const url: string = `${env.apiGatewayUri}/submissions/uploaddoc`;
    const body: any = {
      FileName: filename,
      RequestSubmissionId: requestSubmissionId
    };
    const response = this._client.post<ApiGatewayResponse<UploadDocResult>>(url, body);
    return response;
  }

  /**
   * @deprecated
   */
  public submissionRelease(requestSubmissionId: string): Observable<ApiResponse<any>> {
    const url: string = `${env.apiGatewayUri}/submissions/release`;
    const body: any = {
      RequestSubmissionId: requestSubmissionId
    };
    const response = this._client.post<ReleaseSubmissionResponse>(url, body).pipe(
      map((response: ReleaseSubmissionResponse) => {
        return {
          success: response.statusCode === 200,
          message: response.body.status,
          result: response.body.status
        }
      })
    )
    return response;
  }

  /**
   * @deprecated
   */
  public cancelSubmission(requestSubmissionId: string, statusReasonCode: string): Observable<ApiResponse<any>> {
    const url: string = `${env.apiGatewayUri}/submissions/cancel`;
    const body: any = {
      RequestSubmissionId: requestSubmissionId,
      StatusReasonCode: statusReasonCode
    };
    return this._client.post<CancelSubmissionResponse>(url, body).pipe(
      map((response: CancelSubmissionResponse) => {
        return {
          success: response.statusCode === 200,
          message: response.body.status,
          result: response.body.status
        }
      })
    )
  }

  /**
   * @deprecated
   */
  public async cancelSubmissionSynchronous(requestSubmissionId: string, statusReasonCode: string) {
    
    const url: string = `${env.apiGatewayUri}/submissions/cancel`;
    const body: any = {
      RequestSubmissionId: requestSubmissionId,
      StatusReasonCode: statusReasonCode
    };
    
    const body2: BodyInit = JSON.stringify(body);
    console.log("firing cancel message");
    const result: boolean = navigator.sendBeacon(url, body2);
    console.log("result: ", result);
  }
}
