import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { environment as env } from "../../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthToken } from '../models/AuthToken';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from './api.service';
import { TpoContact } from '../models/TpoContact';
import { map, tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loginSubject = new Subject<any>();

  constructor(
    private _client: HttpClient, 
    private _router: Router, 
    private _notification: NzNotificationService) { }

  public getAccessToken(): Observable<string> {
    let payload: any = this.getToken();

    if (payload) {
      let accessToken: string = payload.access_token;
      let result = of(accessToken);
      console.log("access token: ", accessToken);
      return result;
    }
    else {
      // redirect to a protected route to force cognito redirect
      this._router.navigateByUrl("dashboard");
      return null;
    }

   
  }


  public getRefreshToken(): Observable<string> {
    let payload: any = this.getToken();
    let refreshToken: string = payload.refresh_token;
    let result = of(refreshToken);
    console.log("refresh token: ", refreshToken);
    return result;
  }


  public getUserSubject(): Observable<string> {
    let payload: any = this.getToken();
    let idToken: string = payload.id_token;
    let decodedToken: any = jwt_decode(idToken);
    let userSubjectId = decodedToken.sub;
    console.log("user subject id: ", userSubjectId);
    return of(userSubjectId);
  }


  public getUserEmail() {
    let payload: any = this.getToken();
    let idToken: string = payload.id_token;
    let decodedToken: any = jwt_decode(idToken);
    let email = decodedToken.email;
    console.log("email: ", email);
    return of(email);
  }


  public getUsername() {
    let payload: any = this.getToken();
    let idToken: string = payload.id_token;
    let decodedToken: any = jwt_decode(idToken);
    console.log("decoded id token: ", decodedToken);
    let username = decodedToken["cognito:username"];
    console.log("username: ", username);
    return of(username);
  }

  public isAuthenticated(): Observable<boolean> {
    const payload: any = this.getToken();
    const isAuthenticated: boolean = payload != null;
    console.log("is authenticated: ", isAuthenticated);
    return of(isAuthenticated);
  }


  public getCurrentAuthenticatedUser(): Observable<null> {
    return null;
  }


  public getCognitoLoginUrl() {
    const domain = env.auth.domain;
    const clientId = env.auth.userPoolWebClientId;
    const redirectUrl = env.auth.redirectSignIn;
    const scope = env.auth.scopes.join(" ");
    const url = `https://${domain}/login?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scope}`;
    const encodedUrl = encodeURI(url);
    return encodedUrl;
  }

  public getCognitoLogoutUrl() {
    let domain = env.auth.domain;
    let clientId = env.auth.userPoolWebClientId;
    let logoutUri = env.auth.redirectSignOut;
    let url = `https://${domain}/logout?client_id=${clientId}&logout_uri=${logoutUri}`;
    let encodedUrl = encodeURI(url);
    console.log("logout url: ", encodedUrl);
    return encodedUrl;
  }


  public logout() {
    //localStorage.removeItem("token_payload");
    localStorage.clear();
    window.location.href = this.getCognitoLogoutUrl();
  }










  public exchangeCodeForToken(code: string): Observable<AuthToken> {
    let domain: string = env.auth.domain;
    let redirectUri = env.auth.redirectSignIn;
    let clientId = env.auth.userPoolWebClientId;
    const url = `https://${domain}/oauth2/token`;
    const body = new HttpParams()
      .set("grant_type", "authorization_code")
      .set("code", code)
      .set("client_id", clientId)
      .set("redirect_uri", redirectUri);
    
    const headers = new HttpHeaders()
      .set("Content-Type", "application/x-www-form-urlencoded");

    const response = this._client.post<AuthToken>(url, body, { headers: headers });

    return response.pipe(
      tap((authToken: AuthToken) => console.log("exchange code for token response: ", authToken)),
      tap((authToken: AuthToken) => this.saveToken(authToken))
    );

  }



  public saveToken(token: AuthToken) {

    this.loginSubject.next({event: "login"});

    const bufferInSeconds: number = 15;
    const expiration = (token.expires_in - bufferInSeconds) * 1000;
    //let expiration: number = 300 * 1000; // 5 minutes
    localStorage.setItem("token_payload", JSON.stringify(token));
    //localStorage.setItem("tpo_contact", JSON.stringify(tpoContact));
    setTimeout(() => {
      console.log("deleting token from local storage");
      this.logout();
      //localStorage.removeItem("tpo_contact");
    }, expiration);

  }


  public getToken(): AuthToken {
    const val: string = localStorage.getItem("token_payload");



    let token: AuthToken | null = null;

    if (val)
    {
      token = JSON.parse(val) as AuthToken;
      const decoded = jwt_decode(token.access_token) as any;
      if (Date.now() > (decoded.exp * 1000)) 
      {
        this.logout();
      }
    }
    else
    {
      this.logout();
    }

    return token;
  }

  public loggedInUserHasAdminEmailDomain(): Observable<boolean> {
    const adminEmailDomains: string[] = env.adminEmailDomains;
    const result: Observable<boolean> = this.getUserEmail().pipe(
      map((email: string) => {
        return adminEmailDomains.some((domain: string) => {
          return email.endsWith(domain);
        })
      })
    );

    return result;
  }



}
