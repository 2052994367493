import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinPipe } from './pipes/join.pipe';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';

const NZ_MODULES: any[] = [
  NzButtonModule,
  NzLayoutModule,
  NzMenuModule,
  NzUploadModule,
  NzMessageModule,
  NzInputModule,
  NzTableModule,
  NzDropDownModule,
  NzDividerModule,
  NzBadgeModule,
  NzDescriptionsModule,
  NzPageHeaderModule,
  NzTypographyModule,
  NzTagModule,
  NzListModule,
  NzAvatarModule,
  NzCardModule,
  NzGridModule,
  NzModalModule,
  NzResultModule,
  NzSkeletonModule,
  NzDatePickerModule,
  NzAnchorModule,
  NzDrawerModule,
  NzFormModule,
  NzSelectModule,
  NzRateModule,
  NzRadioModule,
  NzPopconfirmModule,
  NzToolTipModule,
  NzAlertModule,
  NzNotificationModule,
  NzBreadCrumbModule,
  NzStepsModule,
  NzSwitchModule,
  NzStatisticModule,
  NzIconModule
];


@NgModule({
  declarations: [
    JoinPipe
  ],
  imports: [
    CommonModule,
    
    ...NZ_MODULES
  ],
  exports: [
    JoinPipe,
    ...NZ_MODULES
  ]
})
export class SharedModule { }
