import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { IdleTimeoutService } from './core/services/idle-timeout.service';
import { SplashScreenService } from './core/services/splash-screen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  // Properties to manage the Idle Timeout Modal
  private _timeout = new BehaviorSubject<{ minutes: number, seconds: number }>({minutes: 0, seconds: 0});
  public timeout$ = this._timeout.asObservable();
  public idleTimeoutModalVisible = false;

  constructor(
    private _splashScreenService: SplashScreenService, 
    private _idleTimeoutService: IdleTimeoutService,
    private _authService: AuthService
  ) { }


  ngOnInit(): void {
    this._idleTimeoutService.initialize();

    this._idleTimeoutService.timeout.subscribe({
      next: (timeout: any) => {
        //console.log("timeout: ", timeout);

        this.idleTimeoutModalVisible = true;
        this._timeout.next(timeout);
      }
    })
    
  }




  public onSignOut(): void {
    this._authService.logout();
  }


  public onContinue(): void {
    this.idleTimeoutModalVisible = false;
    this._idleTimeoutService.resetTimer();
  }






}
