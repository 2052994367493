import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/models/api';
import { BsaSubmissionRequest } from 'src/app/core/models/BsaSubmissionRequest';
import { S3ResponseItem } from 'src/app/core/models/S3ResponseItem';
import { TpoContact } from 'src/app/core/models/TpoContact';
import { UploadDocResult } from 'src/app/core/models/UploadDocResult';
import { UploadMetadata } from 'src/app/core/models/UploadMetadata';
import { UploadResult } from 'src/app/core/models/UploadResult';
import { ApiGatewayService } from 'src/app/core/services/api-gateway.service';
import { ApiService } from 'src/app/core/services/api.service';
import { S3Service } from 'src/app/core/services/s3.service';
import { TpoContactService } from 'src/app/core/services/tpo-contact.service';
import { environment as env } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BsaService {

  constructor(
    private _apiGateway: ApiGatewayService,
    private _api: ApiService,
    private _tpo: TpoContactService,
    private _s3: S3Service
  ) { }

  /**
   * Generates a GUID to use as the request submission id.
   */
  public generateRequestSubmissionId(): Observable<ApiResponse<string>> {
    const response$ = this._api.generateRequestSubmissionId();
    return response$;
  }

  /**
   * Cancels the document processing request submission
   */
  public cancelSubmission(requestSubmissionId: string, statusReasonCode: string): Observable<ApiResponse<string>> {
    const response$ = this._api.cancelSubmission(requestSubmissionId, statusReasonCode);
    return response$;
  }

  /**
   * Releases the document processing request submission
   */
  public releaseSubmission(requestSubmissionId: string): Observable<ApiResponse<string>> {
    const response$ = this._api.releaseSubmission(requestSubmissionId);
    return response$;
  }


  /**
   * Submits the BSA request
   */
  public submit(form: UntypedFormGroup, requestSubmissionId: string, tpoContactEmail: string): Observable<ApiResponse<any>> {
    const request: BsaSubmissionRequest = this.createSubmissionRequest(form, requestSubmissionId, tpoContactEmail);
    const response$ = this._api.submit(request);
    return response$;
  }

  public generatePresignedUrlsForDocuments(requestSubmissionId: string, files: NzUploadFile[]) {
    return this._api.generatePresignedUrlForDocuments(files.map(x => x.name), requestSubmissionId);
  }

  public checkApiAgainstS3Uploads(items: S3ResponseItem[]): Observable<UploadResult[]> {
    const data = this._api.getMetadataForDocumentUploads(items.map((item) => item.key)).pipe(
      map((response: ApiResponse<UploadMetadata[]>) => {
        const results = response.result.map((meta: UploadMetadata) => {
          const result: UploadResult = {
            key: meta.key,
            success: meta.exists && meta.size > 0,
            uid: items.find((item: S3ResponseItem) => item.key === meta.key).uid,
            filename: items.find((item: S3ResponseItem) => item.key === meta.key).filename
          };

          return result;
        })

        return results;
      })
    );

    return data;
  }

  // TODO: Replace this with direct to api and api gateway fallback
  public async cancelSubmissionSynchronous(requestSubmissionId: string, statusReasonCode: string) {
    return this._apiGateway.cancelSubmissionSynchronous(requestSubmissionId, statusReasonCode);
  }


  private createSubmissionRequest(form: UntypedFormGroup, requestSubmissionId: string, tpoContactEmail: string): BsaSubmissionRequest {
    const contact: TpoContact = this._tpo.get();

    const requestNotes: string = form.value.notes;

    const result: BsaSubmissionRequest = {
      DataToEncrypt: {Ssn: ""},
      RequestSubmissionId: requestSubmissionId,
      DocumentProcessingRequest: {
        AccountHolders: [
          {
            email: "",
            firstName: form.value.borrower,
            lastName: form.value.business,
            address: "",
            birthDate: "",
            city: "",
            last4Ssn: "",
            phoneNumber: "",
            state: "",
            zipCode: ""
          }
        ],
        EncompassClientId: contact.clientId,
        MonthsOfStatements: form.value.monthsOfStatements,
        RequestType: "BANKSTATEMENTS",
        TpoContactEmail: tpoContactEmail,
        WorkflowType: "BROKER_LOAN_OPPORTUNITY_INCOME_ANALYSIS",
        Attributes: { AccountType: form.value.accountType },
        RequestNotes: `Type and Nature of Business and Additional Notes\n\r${requestNotes}`,
        EncompassUserEmail: null,
        EncompassUserId: null,
        EncompassUserPhoneNumber: null,
        EncompassUserFirstName: null,
        EncompassUserLastName: null
      }
    };

    return result;
  }
}
