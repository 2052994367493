import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { environment as env } from 'src/environments/environment';
import { BsaIncompleteSubmissionService } from './bsa-incomplete-submission.service';


@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {

  private timeoutInMinutes: number = env.idleTimeoutMinutes;
  private numberOfSecondsBeforeTimeoutToShowWarningMessage = 121;
  
  private millisecondsPerSecond: number = 1000;
  private secondsPerMinute: number = 60;
  private minutesPerHour: number = 60;

  private _timeout: Subject<{ minutes: number, seconds: number }> = new Subject();
  public timeout: Observable<{ minutes: number, seconds: number }> = this._timeout.asObservable();

  private onSessionExpiredHandler: any;
  private onCheckForSessionExpirationHandler: any;

  public isSessionAboutToExpire$ = new Subject<boolean>();

  constructor(
    private _authService: AuthService, 
    private _bsaIncompleteSubmissionService: BsaIncompleteSubmissionService
  ) { }

  public initialize(): void {
    this.startTimer();
    this.setupEventListenersThatResetTimer();
  }


  public resetTimer(): void {
    clearTimeout(this.onSessionExpiredHandler);
    clearTimeout(this.onCheckForSessionExpirationHandler);
    this.startTimer()
  }


  /**
   * Set the session expiration date time in local storage
   * Sign the user out when the session expires
   */
  private startTimer(): void {

    this.setSessionExpiresDateTime();
    const millisecondsUntilSessionExpires: number = this.calculateMillisecondsUntilSessionExpires();


    this.onSessionExpiredHandler = setTimeout(() => this.onSessionExpired(), millisecondsUntilSessionExpires);
    this.onCheckForSessionExpirationHandler = setInterval(() => this.onCheckForSessionExpiration(), 1000);
    
    
  }


  private setSessionExpiresDateTime(): void {
    const currentDateTime: Date = new Date();
    const sessionExpiresDateTime: Date = new Date(currentDateTime.setMinutes(currentDateTime.getMinutes() + this.timeoutInMinutes));
    localStorage.setItem("_timeout", sessionExpiresDateTime.toString());
  }


  private getSessionExpiresDateTime(): Date {
    const sessionExpiresDateTimeString: string = localStorage.getItem("_timeout");
    const sessionExpiresDateTime = new Date(Date.parse(sessionExpiresDateTimeString));
    return sessionExpiresDateTime;
  }





  private calculateSecondsUntilSessionExpires(): number {
    const millisecondsUntilSessionExpires: number = this.calculateMillisecondsUntilSessionExpires();
    const secondsUntilSessionExpires: number = millisecondsUntilSessionExpires / 1000;
    return secondsUntilSessionExpires;
  }


  private calculateMillisecondsUntilSessionExpires(): number {
    const sessionExpiresDateTime: Date = this.getSessionExpiresDateTime();
    const millisecondsUntilSessionExpires: number = sessionExpiresDateTime.getTime() - new Date().getTime();
    return millisecondsUntilSessionExpires;
  }


  private isSessionAboutToExpire(): boolean {
    return this.numberOfSecondsBeforeTimeoutToShowWarningMessage >= this.calculateSecondsUntilSessionExpires();
  }
  

  private setupEventListenersThatResetTimer(): void {
    document.addEventListener("click", (event) => {
      //sconsole.log("event: ", event);
      if (this.isSessionAboutToExpire()) {
        // Don't reset the timer on document click
        // A modal should be displayed at this point and
        // clicking continue is the only way to proceed from there
      }
      else {
        this.resetTimer();
      }
      
      
    });
  }




  private calculateTimeout(): { minutes: number, seconds: number } {
    const sessionExpiresDateTime: Date = this.getSessionExpiresDateTime();
    const millisecondsUntilSessionExpires: number = sessionExpiresDateTime.getTime() - new Date().getTime();
    const seconds: number = Math.floor((millisecondsUntilSessionExpires) / (this.millisecondsPerSecond) % this.secondsPerMinute);
    const minutes: number = Math.floor((millisecondsUntilSessionExpires) / (this.millisecondsPerSecond * this.minutesPerHour) % this.secondsPerMinute);
    const timeout = {
      minutes: minutes,
      seconds: seconds
    };
    return timeout;
  }



  private onSessionExpired(): void {
    const statusReasonCode: string = "CANCELLATION_BY_TIMEOUT";
    const isSubmissionIncomplete = this._bsaIncompleteSubmissionService.isIncomplete();

    if (isSubmissionIncomplete) {
      const result$ = this._bsaIncompleteSubmissionService.cancelSubmissionWithoutWarningModal(statusReasonCode);
      result$.subscribe({
        next: (result) => {
          this._authService.logout();
        }
      })
    }
    else {
      this._authService.logout();
    }


    
  }

  private onCheckForSessionExpiration(): void {
    const isSessionAboutToExpire: boolean = this.isSessionAboutToExpire();

    if (isSessionAboutToExpire) {
      const timeout = this.calculateTimeout();
      this._timeout.next(timeout);
    }
  }



}
