import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import {
  NzModalRef,
  NzModalService,
  OnClickCallback,
} from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { combineAll, finalize, map, mergeAll } from 'rxjs/operators';
import { BsaService } from 'src/app/modules/bsa/services/bsa.service';
import { ApiResponse } from '../models/api';

@Injectable({
  providedIn: 'root',
})
export class BsaIncompleteSubmissionService {
  private _isBsaFormFieldsIncomplete = new BehaviorSubject(false);
  private _isFileUploadIncomplete = new BehaviorSubject(false);
  private _requestSubmissionId = new BehaviorSubject<string | null>(null);

  constructor(
    private _modal: NzModalService,
    private _bsaService: BsaService
  ) {}

  public initializeSubmission(requestSubmissionId: string): void {
    this._requestSubmissionId.next(requestSubmissionId);
    this._isBsaFormFieldsIncomplete.next(false);
    this._isFileUploadIncomplete.next(false);
  }

  public isIncomplete(): boolean {
    console.log('bsa form incomplete: ', this._isBsaFormFieldsIncomplete.value);
    console.log('file upload incomplete: ', this._isFileUploadIncomplete.value);
    const isIncomplete: boolean =
      this._isBsaFormFieldsIncomplete.value ||
      this._isFileUploadIncomplete.value;
    return isIncomplete;
  }

  public setIsBsaFormDirty(isDirty: boolean): void {
    this._isBsaFormFieldsIncomplete.next(isDirty);
  }

  public setIsFileUploadIncomplete(isIncomplete: boolean): void {
    this._isFileUploadIncomplete.next(isIncomplete);
  }

  /**
   * Reset the submission workflow
   */
  public reset(): void {
    this._isBsaFormFieldsIncomplete.next(false);
    this._isFileUploadIncomplete.next(false);
    this._requestSubmissionId.next(null);
  }

  public createIncompleteSubmissionWarningModal(
    cancellationReasonCode: string
  ): NzModalRef<unknown, any> {
    const ref = this._modal.warning({
      nzTitle: 'Incomplete Submission',
      nzContent:
        'It appears that a Bank Statement Analysis request has been started but remains incomplete. A submission that is not finalized will be cancelled. Are you sure you would like to cancel?',
      nzClosable: false,
      nzOkText: 'Cancel',
      nzCancelText: 'Continue',
      nzOnOk: this.onConfirmCancelSubmission(cancellationReasonCode),
      nzOnCancel: () => {
        return new Promise((resolve, reject) => {
          resolve('SUBMISSION_CONTINUED');
        });
      },
    });

    return ref;
  }

  /**
   * Cancel the current in-progress submission without showing a confirmation dialog.
   * This function is invoked when the user's session times out
   * @param statusReasonCode The status reason code
   * @returns an observable
   */
  public cancelSubmissionWithoutWarningModal(
    statusReasonCode: string
  ): Observable<ApiResponse<string>> {
    return this._bsaService
      .cancelSubmission(this._requestSubmissionId.value, statusReasonCode)
      .pipe(finalize(() => this.reset()));
  }

  /**
   * Cancel the current in-progress submission without showing a confirmation dialog.
   * This function is invoked when the user exits the browser tab
   * @param statusReasonCode The status reason code
   * @returns an observable
   */
  public cancelSubmissionSynchronous(statusReasonCode: string) {
    this._bsaService.cancelSubmissionSynchronous(
      this._requestSubmissionId.value,
      statusReasonCode
    );
  }

  /**
   * Send a request to the api gateway endpoint to cancel the current submission
   * @returns a callback function to cancel the submission
   */
  private onConfirmCancelSubmission(
    cancellationReasonCode: string
  ): OnClickCallback<any> {
    return (instance: any) => {
      console.log('cancellation reason code: ', cancellationReasonCode);
      const result$ = this._bsaService
        .cancelSubmission(
          this._requestSubmissionId.value,
          cancellationReasonCode
        )
        .pipe(
          map((response: ApiResponse<string>) => 'SUBMISSION_CANCELLED'),
          finalize(() => this.reset())
        );
      return result$.toPromise();
    };
  }
}
