

<router-outlet></router-outlet>

<!-- Idle Timeout Modal -->
<nz-modal [(nzVisible)]="idleTimeoutModalVisible" nzTitle="Session Timeout" [nzFooter]="null" [nzMaskClosable]="false" [nzClosable]="false" nzWidth="340px">
  <ng-container *nzModalContent>
    <div class="text-center mb-3">
      <span class="timeout-value">{{ (timeout$ | async).minutes }}</span>
      <span class="timeout-label">minutes</span>
      <span class="timeout-value">{{ (timeout$ | async).seconds }}</span>
      <span class="timeout-label">seconds</span>
    </div>

    <div class="text-center mb-4">
      <p>
        Please click <strong><i>Continue</i></strong> to keep working,
        or <strong><i>Sign Out</i></strong> to end your session now.
      </p>
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <button nz-button nzSize="large" (click)="onSignOut()"  class="mr-3">Sign Out</button>
      <button nz-button nzSize="large" (click)="onContinue()" nzType="primary">Continue</button>
    </div>
  </ng-container>
</nz-modal>

