import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppLayoutComponent } from './layouts/app/app-layout.component';


// NG ZORRO Imports

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzCardModule } from "ng-zorro-antd/card";
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzResultModule } from "ng-zorro-antd/result";
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzAnchorModule } from "ng-zorro-antd/anchor";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRateModule } from "ng-zorro-antd/rate";
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzStatisticModule } from "ng-zorro-antd/statistic";

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { IconsProviderModule } from './icons-provider.module';
import { RouterModule, Routes } from '@angular/router';
import { BlankLayoutComponent } from './layouts/blank/blank-layout.component';
import { LandingComponent } from './pages/landing/landing.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { BankStatementCalculatorComponent } from './pages/bank-statement-calculator/bank-statement-calculator.component';
import { SsnDirective } from './shared/directives/ssn.directive';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { SharedModule } from './shared/shared.module';
import { BsaModule } from './modules/bsa/bsa.module';

registerLocaleData(en);




const NZ_MODULES: any[] = [
  NzButtonModule,
  NzLayoutModule,
  NzMenuModule,
  NzUploadModule,
  NzMessageModule,
  NzInputModule,
  NzTableModule,
  NzDropDownModule,
  NzDividerModule,
  NzBadgeModule,
  NzDescriptionsModule,
  NzPageHeaderModule,
  NzTypographyModule,
  NzTagModule,
  NzListModule,
  NzAvatarModule,
  NzCardModule,
  NzGridModule,
  NzModalModule,
  NzResultModule,
  NzSkeletonModule,
  NzDatePickerModule,
  NzAnchorModule,
  NzDrawerModule,
  NzFormModule,
  NzSelectModule,
  NzRateModule,
  NzRadioModule,
  NzPopconfirmModule,
  NzToolTipModule,
  NzAlertModule,
  NzNotificationModule,
  NzBreadCrumbModule,
  NzStepsModule,
  NzSwitchModule,
  NzStatisticModule
];





const routes: Routes = [
  {
    path: "",
    component: BlankLayoutComponent,
    loadChildren: () => import("./app-routing.module").then(m => m.AppRoutingModule)
  }
];





@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    BlankLayoutComponent,
    DashboardComponent,
    LandingComponent,
    UnauthorizedComponent,
    BankStatementCalculatorComponent,
    SsnDirective,

    MaintenanceComponent,

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    ...NZ_MODULES,
    RouterModule.forRoot(routes),
    SharedModule,
    BsaModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApp,
    //   deps: [AppInitializerService],
    //   multi: true
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// export function initializeApp(appInitializerService: AppInitializerService) {
//   return () => appInitializerService.loadIppServiceConfig();
// }
